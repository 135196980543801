/* eslint-disable import/extensions */
import Vue from 'vue'
import { event as gtagEvent } from 'vue-gtag'
const apiUrl = process.env.analytics_api_url

export const getVisitorID = async cookies => {
	const visitorId = cookies.get('visitor_id')

	cookies.set('visitor_id', visitorId, { maxAge: 365 * 24 * 60 * 60, domain: 'stanwith.me' })
	cookies.set('visitor_id', visitorId, { maxAge: 365 * 24 * 60 * 60, domain: 'stan.store' })
	cookies.set('visitor_id', visitorId, { maxAge: 365 * 24 * 60 * 60 })
	return visitorId
}

export const UUID = function () {
	let lut = []
	for (var i = 0; i < 256; i++) {
		lut[i] = (i < 16 ? '0' : '') + i.toString(16)
	}
	var d0 = (Math.random() * 0xffffffff) | 0
	var d1 = (Math.random() * 0xffffffff) | 0
	var d2 = (Math.random() * 0xffffffff) | 0
	var d3 = (Math.random() * 0xffffffff) | 0
	return (
		lut[d0 & 0xff] +
		lut[(d0 >> 8) & 0xff] +
		lut[(d0 >> 16) & 0xff] +
		lut[(d0 >> 24) & 0xff] +
		'-' +
		lut[d1 & 0xff] +
		lut[(d1 >> 8) & 0xff] +
		'-' +
		lut[((d1 >> 16) & 0x0f) | 0x40] +
		lut[(d1 >> 24) & 0xff] +
		'-' +
		lut[(d2 & 0x3f) | 0x80] +
		lut[(d2 >> 8) & 0xff] +
		'-' +
		lut[(d2 >> 16) & 0xff] +
		lut[(d2 >> 24) & 0xff] +
		lut[d3 & 0xff] +
		lut[(d3 >> 8) & 0xff] +
		lut[(d3 >> 16) & 0xff] +
		lut[(d3 >> 24) & 0xff]
	)
}

export const getSessionUUID = cookies => {
	// We save session for 10 minutes and renew every time there's an event to be tracked
	const sessionUuid = cookies.get('session_uuid') || UUID()
	cookies.set('session_uuid', sessionUuid, { maxAge: 60 * 10, domain: 'stanwith.me' })
	cookies.set('session_uuid', sessionUuid, { maxAge: 60 * 10, domain: 'stan.store' })
	cookies.set('session_uuid', sessionUuid, { maxAge: 60 * 10 })
	return sessionUuid
}

export const getCookieUUID = cookies => {
	const sessionUuid = cookies.get('cookie_uuid') || UUID()
	cookies.set('cookie_uuid', sessionUuid, { maxAge: 365 * 24 * 60 * 60, domain: 'stanwith.me' })
	cookies.set('cookie_uuid', sessionUuid, { maxAge: 365 * 24 * 60 * 60, domain: 'stan.store' })
	cookies.set('cookie_uuid', sessionUuid, { maxAge: 365 * 24 * 60 * 60 })
	return sessionUuid
}

export default function ({ store, $cookies, $getFlags }, inject) {
	function trigger(eventName, options) {
		const payload = {}
		payload.n = eventName
		payload.u = window.location.href
		payload.r = window.document.referrer || null
		payload.w = window.innerWidth
		if (options && options.meta) {
			payload.m = options.meta
		}

		if (!('m' in payload)) {
			payload.m = {}
		}

		payload.m.platform = {
			oscpu: navigator.oscpu,
			vendor: navigator.vendor,
			platform: navigator.platform,
			cpuClass: navigator.cpuClass,
			appName: navigator.appName,
			appVersion: navigator.appVersion,
			codeName: navigator.appCodeName,
			userAgent: navigator.userAgent,
		}

		if (payload.r === null || payload.r === '') {
			const platform = payload.m.platform
			if (platform && platform.userAgent && platform.userAgent.includes('musical_ly')) {
				payload.r = 'https://www.tiktok.com/'
			}
		}

		if (options && options.props) {
			payload.p = options.props
		}

		const request = new XMLHttpRequest()
		request.open('POST', apiUrl, true)
		request.setRequestHeader('Content-Type', 'application/json')
		request.send(JSON.stringify(payload))

		//google analytics
		var flattenOptios = Object.assign({}, payload.m, payload.p)
		gtagEvent(eventName, flattenOptios)

		request.onreadystatechange = function () {
			if (request.readyState === 4) {
				options && options.callback && options.callback()
			}
		}
	}

	const getAnalyticsData = async (opts = {}) => {
		const meta = opts.meta || {}
		const props = opts.props || {}
		const flagData = $getFlags()
		// Plugin default metadata
		meta.ip = store.getters.getIPAddress
		meta.sessionUUID = getSessionUUID($cookies)
		meta.cookieUUID = getCookieUUID($cookies)
		meta.visitorId = await getVisitorID($cookies)
		props.experiments = flagData
		return {
			meta,
			props,
		}
	}

	const callAnalytics = async (name, opts = {}) => {
		const { meta, props } = await getAnalyticsData(opts)
		// eslint-disable-next-line no-undef
		trigger(name, { meta, props })
		return true
	}

	inject('getAnalyticsData', getAnalyticsData)
	inject('stanAnalytics', callAnalytics)
}
